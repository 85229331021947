@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  .form {
    display: flex;
    border-top: 0.5px solid #D3D3D3;
  }
  
  .input {
    /* border-top: 0.5px solid #D3D3D3; */
    /* border-radius: 0; */
    border: none;
    padding-left: 5%;
    width: 80%;
    font-size: 1em;
    font-family: 'Raleway', sans-serif;
  }
  
  input:focus, textarea:focus, select:focus{
    outline: none;
  }
  
  .sendButton {
    color: #fff !important;
    text-transform: uppercase;
    text-decoration: none;
    background: #5f68ab;
    padding: 20px;
    display: inline-block;
    border: none;
    width: 20%;
    font-family: 'Raleway', sans-serif;
  }