@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.messageBox {
    background: #F3F3F3;
    border-radius: 20px;
    padding: 0.2px 20px;
    color: white;
    display: inline-block;
    max-width: 80%;
  }

  .adminMessage {
    font-family: 'Raleway', sans-serif;
    font-style: italic;
  }
  
  .messageText {
    width: 100%;
    letter-spacing: 0;
    float: left;
    font-size: 1em;
    word-wrap: break-word;
    font-family: 'Raleway', sans-serif;
  }
  
  .messageText img {
    vertical-align: middle;
  }
  
  .messageContainer {
    display: flex;
    justify-content: flex-end;
    padding: 0 5%;
    margin-top: 3px;
  }
  
  .sentText {
    display: flex;
    align-items: center;
    font-family: Helvetica;
    color: #828282;
    letter-spacing: 0.3px;
  }
  
  .pl-10 {
    padding-left: 10px;
  }
  
  .pr-10 {
    padding-right: 10px;
  }
  
  .justifyStart {
    justify-content: flex-start;
  }
  
  .justifyEnd {
    justify-content: flex-end;
  }
  
  .colorWhite {
    color: white;
  }
  
  .colorDark {
    color: #353535;
  }

  .colorAdminMsg {
    color: #797979;
  }
  
  .backgroundBlue {
    background: #5f68ab;
  }
  
  .backgroundLight {
    background: #F3F3F3;
  }