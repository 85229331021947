@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.textContainer {
  display: flex;
  flex-direction: column;
  color: white;
  background-color:#353535;
  height: 60%;
  justify-content: right;
  border-radius: 0px 4px 0 0;
}

.header{
  padding-left: 3em !important;
  padding-right: 3em !important;
  padding-top: 0.21em !important;
  padding-bottom: 0.21em !important;
  font-family: 'Raleway', sans-serif;
  border-radius: 0px 4px 0 0;
}

.headerContainer{
  background-color: #664980;
  border-radius: 0px 4px 0 0;
}

.imageIcon{
  padding-right: 0.5em;
}

.activeContainer {
  display: flex;
  align-items: center !important;
}

.userName{
  font-family: 'Raleway', sans-serif;
  font-size: 1.2em;
  font-weight: 200;
}

.listItem{
  padding-left: 0.5em;
  padding-right: 2em;
}

.activeItem {
  display: flex;
  align-items: center;
  font-family: 'Raleway', sans-serif;
  font-size: 0.5em !important;
  padding-bottom: 1em;;
}

.activeContainer img {
  padding-left: 10px;
}

.textContainer h1 {
  margin-bottom: 0px;
}

@media (min-width: 320px) and (max-width: 1200px) {
  .textContainer {
    display: none;
  }
}