@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.infoBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #5f68ab;
    border-radius: 4px 0px 0 0;
    height: 60px;
    width: 100%;
  }
.roomName{
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
}
  
  .leftInnerContainer {
    flex: 0.5;
    display: flex;
    align-items: center;
    margin-left: 5%;
    color: white;
  }
  
  .rightInnerContainer {
    display: flex;
    flex: 0.5;
    justify-content: flex-end;
    margin-right: 5%;
  }
  
  .onlineIcon {
    margin-right: 5%;
  }