@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
html, body {
    font-family: 'Raleway', sans-serif;
    padding: 0;
    margin: 0;
  }
  
  #root {
    height: 100vh;
  }
  
  * {
    box-sizing: border-box;
  }
  
  .joinOuterContainer {
    display: flex;
    justify-content: center;
    text-align: center;
    height: 100vh;
    align-items: center;
    background-color: #1A1A1D;
  }
  
  .joinInnerContainer {
    margin-top: -2em;
    width: 20%;
  }

  .logo{
    width: 100px;
    height: 100px
  }
  
  .joinInput {
    border-radius: 0;
    padding: 15px 20px;
    width: 100%;
    font-family: 'Raleway', sans-serif;
  }
  
  .heading {
    color: white;
    font-size: 2.5em;
    font-weight: 100;
    padding-bottom: 10px;
    border-bottom: 2px solid white;
    font-family: 'Raleway', sans-serif;
  }
  
  .button {
      color: #fff !important;
      text-transform: uppercase;
      text-decoration: none;
      background: #5f68ab;
      padding: 20px;
      border-radius: 5px;
      display: inline-block;
      border: none;
      width: 100%;
      font-family: 'Raleway', sans-serif;
  }
  
  .mt-20 {
    margin-top: 20px;
  }
  
  @media (min-width: 320px) and (max-width: 480px) {
    .joinOuterContainer {
      height: 100%;
    }
  
    .joinInnerContainer {
      width: 90%;
    }
  
  
  }
  
  button:focus {
    outline: 0;
  }
.outerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1A1A1D;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #FFFFFF;
    border-radius: 8px;
    height: 60%;
    width: 35%;
  }
  
  @media (min-width: 320px) and (max-width: 480px) {
    .outerContainer {
      height: 100%;
    }
  
    .container {
      width: 100%;
      height: 100%;
    }
  }
  
  @media (min-width: 480px) and (max-width: 1200px) {
    .container {
      width: 60%;
    }
  }
.infoBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #5f68ab;
    border-radius: 4px 0px 0 0;
    height: 60px;
    width: 100%;
  }
.roomName{
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
}
  
  .leftInnerContainer {
    flex: 0.5 1;
    display: flex;
    align-items: center;
    margin-left: 5%;
    color: white;
  }
  
  .rightInnerContainer {
    display: flex;
    flex: 0.5 1;
    justify-content: flex-end;
    margin-right: 5%;
  }
  
  .onlineIcon {
    margin-right: 5%;
  }
.form {
    display: flex;
    border-top: 0.5px solid #D3D3D3;
  }
  
  .input {
    /* border-top: 0.5px solid #D3D3D3; */
    /* border-radius: 0; */
    border: none;
    padding-left: 5%;
    width: 80%;
    font-size: 1em;
    font-family: 'Raleway', sans-serif;
  }
  
  input:focus, textarea:focus, select:focus{
    outline: none;
  }
  
  .sendButton {
    color: #fff !important;
    text-transform: uppercase;
    text-decoration: none;
    background: #5f68ab;
    padding: 20px;
    display: inline-block;
    border: none;
    width: 20%;
    font-family: 'Raleway', sans-serif;
  }
.messageBox {
    background: #F3F3F3;
    border-radius: 20px;
    padding: 0.2px 20px;
    color: white;
    display: inline-block;
    max-width: 80%;
  }

  .adminMessage {
    font-family: 'Raleway', sans-serif;
    font-style: italic;
  }
  
  .messageText {
    width: 100%;
    letter-spacing: 0;
    float: left;
    font-size: 1em;
    word-wrap: break-word;
    font-family: 'Raleway', sans-serif;
  }
  
  .messageText img {
    vertical-align: middle;
  }
  
  .messageContainer {
    display: flex;
    justify-content: flex-end;
    padding: 0 5%;
    margin-top: 3px;
  }
  
  .sentText {
    display: flex;
    align-items: center;
    font-family: Helvetica;
    color: #828282;
    letter-spacing: 0.3px;
  }
  
  .pl-10 {
    padding-left: 10px;
  }
  
  .pr-10 {
    padding-right: 10px;
  }
  
  .justifyStart {
    justify-content: flex-start;
  }
  
  .justifyEnd {
    justify-content: flex-end;
  }
  
  .colorWhite {
    color: white;
  }
  
  .colorDark {
    color: #353535;
  }

  .colorAdminMsg {
    color: #797979;
  }
  
  .backgroundBlue {
    background: #5f68ab;
  }
  
  .backgroundLight {
    background: #F3F3F3;
  }
.messages {
    padding: 5% 0;
    overflow: auto;
    flex: auto;
  }
.textContainer {
  display: flex;
  flex-direction: column;
  color: white;
  background-color:#353535;
  height: 60%;
  justify-content: right;
  border-radius: 0px 4px 0 0;
}

.header{
  padding-left: 3em !important;
  padding-right: 3em !important;
  padding-top: 0.21em !important;
  padding-bottom: 0.21em !important;
  font-family: 'Raleway', sans-serif;
  border-radius: 0px 4px 0 0;
}

.headerContainer{
  background-color: #664980;
  border-radius: 0px 4px 0 0;
}

.imageIcon{
  padding-right: 0.5em;
}

.activeContainer {
  display: flex;
  align-items: center !important;
}

.userName{
  font-family: 'Raleway', sans-serif;
  font-size: 1.2em;
  font-weight: 200;
}

.listItem{
  padding-left: 0.5em;
  padding-right: 2em;
}

.activeItem {
  display: flex;
  align-items: center;
  font-family: 'Raleway', sans-serif;
  font-size: 0.5em !important;
  padding-bottom: 1em;;
}

.activeContainer img {
  padding-left: 10px;
}

.textContainer h1 {
  margin-bottom: 0px;
}

@media (min-width: 320px) and (max-width: 1200px) {
  .textContainer {
    display: none;
  }
}
